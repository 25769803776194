import $ from "jquery";
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.css'
import "./index.css";

$(document).ready(function () {
    $(document).click(
        function (event) {
            var target = $(event.target);
            var _menuOpen = $(".navbar-collapse").hasClass("show");
            if (_menuOpen === true && !target.hasClass("navbar-toggler")) {
                $("button.navbar-toggler").click();
            }
        }
    );
});
